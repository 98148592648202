import {useEffect, useRef, useState} from 'react';

// import owner from '../../assets/images/property-owner.png';
// import map_img from '../../assets/images/map.png';
// import ModalVideo from 'react-modal-video';
// import 'react-modal-video/css/modal-video.css';

import {
  PriceCard,
  // RentalFeaturesCard
} from '@shared/cards';
import {
  PropertyListResponse,
  StoryObject,
} from '@interfaces/properties.interface';
// import { Status, Wrapper } from '@googlemaps/react-wrapper';
// import React, { Fragment, useEffect, useRef, useState } from 'react';
// import { deepEqual } from 'fast-equals';
import {Link, useLocation, useNavigate} from 'react-router-dom';
// import Gmap from '@shared/map/Gmap';
// import { InputComponent } from '@shared/forms';
// import { useDeepCompareEffectForMaps } from '../../hooks/useDeep';
import {
  useJsApiLoader,
  // useLoadScript,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,

  // StandaloneSearchBox,
} from '@react-google-maps/api';

import {getSingleProperty} from '@services/krent.service';
import NeighbourStory from '@shared/cards/NeighbourStory';
import PropertyVideo from '@shared/Video';
import {Modal} from '@shared/controls/Modal';
// import Calendar from '../shared/Calender';
import {Modal as NewModal} from 'react-responsive-modal';
import GetMeta from '@pages/SeoPages/GetMeta';

type Props = {
  prop: PropertyListResponse;
};

// const render = (status: Status) => {
//   return <h1>{status}</h1>;
// };
// interface MapProps extends google.maps.MapOptions {
//   style: { [key: string]: string | number };
//   onClick?: (e: google.maps.MapMouseEvent) => void;
//   onIdle?: (map: google.maps.Map) => void;
//   children?: JSX.Element | JSX.Element[];
// }

// const Map: React.FC<MapProps> = ({
//   onClick,
//   onIdle,
//   children,
//   style,
//   ...options
// }) => {
//   const ref = useRef<HTMLDivElement>(null);
//   const [map, setMap] = useState<google.maps.Map>();

//   useEffect(() => {
//     if (ref.current && !map) {
//       setMap(new window.google.maps.Map(ref.current, {}));
//     }
//   }, [ref, map]);

//   useDeepCompareEffectForMaps(() => {
//     if (map) {
//       map.setOptions(options);
//     }
//   }, [ref, map]);

//   useEffect(() => {
//     if (map) {
//       ['click', 'idle'].forEach((eventName) =>
//         google.maps.event.clearListeners(map, eventName)
//       );
//       if (onClick) {
//         map.addListener('click', onClick);
//       }
//       if (onIdle) {
//         map.addListener('idle', () => onIdle(map));
//       }
//     }
//   }, [map, onClick, onIdle]);

//   return (
//     <Fragment>
//       <div ref={ref} style={style}></div>
//       {React.Children.map(children, (child) => {
//         if (React.isValidElement(child)) {
//           // set the map prop on the child component
//           return React.cloneElement(child);
//         }
//       })}
//     </Fragment>
//   );
// };

// const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
//   const [marker, setMarker] = React.useState<google.maps.Marker>();
//   React.useEffect(() => {
//     if (!marker) {
//       setMarker(new google.maps.Marker());
//     }
//     // remove marker from map on unmount
//     return () => {
//       if (marker) {
//         marker.setMap(null);
//       }
//     };
//   }, [marker]);
//   React.useEffect(() => {
//     if (marker) {
//       marker.setOptions(options);
//     }
//   }, [marker, options]);
//   return null;
// };

// const deepCompareEqualsForMaps = (a: any, b: any) => {
//   if (
//     isLatLngLiteral(a) ||
//     a instanceof google.maps.LatLng ||
//     isLatLngLiteral(b) ||
//     b instanceof google.maps.LatLng
//   ) {
//     return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
//   }

//   // TODO extend to other types

//   // use fast-equals for other objects
//   return deepEqual(a, b);
// };

// function useDeepCompareMemoize(value: any) {
//   const ref = React.useRef();

//   if (!deepCompareEqualsForMaps(value, ref.current)) {
//     ref.current = value;
//   }

//   return ref.current;
// }

// function useDeepCompareEffectForMaps(
//   callback: React.EffectCallback,
//   dependencies: any[]
// ) {
//   React.useEffect(callback, [
//     callback,
//     ...dependencies.map(useDeepCompareMemoize),
//   ]);
// }

// function isLatLngLiteral(obj: any): obj is google.maps.LatLngLiteral {
//   return (
//     typeof obj === 'object' &&
//     Number.isFinite(obj.lat) &&
//     Number.isFinite(obj.lng)
//   );
// }

export const SingleProperty: React.FC<Props> = ({prop}: Props) => {
  // const [clicks, setClicks] = useState<google.maps.LatLng[]>([]);
  // const [zoom] = useState(3); // initial zoom
  // const [center] = useState<google.maps.LatLngLiteral>({
  //   lat: 6.5244,
  //   lng: 3.3792,
  // });

  // const onClick = (e: google.maps.MapMouseEvent) => {
  //   // avoid directly mutating state
  //   setClicks([...clicks, e.latLng!]);
  // };

  // const onIdle = (m: google.maps.Map) => {
  // 	console.log("onIdle");
  // 	setZoom(m.getZoom()!);
  // 	setCenter(m.getCenter()!.toJSON());
  // };

  const location: any = useLocation();

  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: process.env['REACT_APP_GOOGLE_API_KEY'] || '',
    libraries: ['places'],
  });
  const [fecthedSingleProp, setFecthedSingleProp] = useState<any>({});
  const [propertyInfo, setPropertyInfo] = useState<any>(prop);
  const [map, setMap] = useState<google.maps.Map>();
  const [directionsResponse, setDirectionsResponse] = useState<any>(null);
  const [distance, setDistance] = useState<any>('');
  const [duration, setDuration] = useState<any>('');
  const [propLatLng, setPropLatLng] = useState<any>('s');
  const [nStory, setNStory] = useState<any>();
  const [des, setDes] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [openAmenities, setOpenAmenities] = useState<boolean>(false);
  const [openRules, setOpenRules] = useState<boolean>(false);
  const [openPolicy, setOpenPolicy] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>(
    prop?.coverImage || prop?.otherImages[0]
  );
  const [center, setCenter] = useState<any>({
    lat: 9.082,
    lng: 8.6753,
  });
  const thumbnailsRef = useRef<HTMLDivElement>(null);
  // const [isOpen, setIsOpen] = useState<any>();
  const handleThumbClick = (image: string) => {
    setSelectedImage(image);
  };

  const handlePrevClick = () => {
    const currentIndex = propertyInfo.otherImages.indexOf(selectedImage);
    if (currentIndex <= 0) {
      setSelectedImage(
        propertyInfo.otherImages[propertyInfo.otherImages.length - 1]
      );
    } else {
      setSelectedImage(propertyInfo.otherImages[currentIndex - 1]);
    }
  };

  const handleNextClick = () => {
    const currentIndex = propertyInfo.otherImages.indexOf(selectedImage);
    if (currentIndex === propertyInfo.otherImages.length - 1) {
      setSelectedImage(propertyInfo.otherImages[0]);
    } else {
      setSelectedImage(propertyInfo.otherImages[currentIndex + 1]);
    }
  };
  // const handleSelectionChange = () => {
  //   const currentIndex = propertyInfo.otherImages.indexOf(selectedImage);
  //   const currentThumb = thumbnailsRef.current?.children[
  //     currentIndex
  //   ] as HTMLImageElement;
  //   if (currentThumb) {
  //     const { x, width } = currentThumb.getBoundingClientRect();
  //     const thumbnailContainerWidth = thumbnailsRef.current?.offsetWidth || 0;
  //     const thumbnailContainerX =
  //       thumbnailsRef.current?.getBoundingClientRect().x || 0;
  //     if (x + thumbnailContainerX < 0) {
  //       thumbnailsRef.current?.scrollTo({
  //         left: x + thumbnailContainerX,
  //         behavior: 'smooth',
  //       });
  //     } else if (x + width + thumbnailContainerX > thumbnailContainerWidth) {
  //       thumbnailsRef.current?.scrollTo({
  //         left: x + width + thumbnailContainerX - thumbnailContainerWidth,
  //         behavior: 'smooth',
  //       });
  //     }
  //   }
  // };
  const handleSelectionChange = () => {
    const currentIndex = propertyInfo?.otherImages.indexOf(selectedImage);
    const currentThumb = thumbnailsRef.current?.children[
      currentIndex
    ] as HTMLImageElement;
    if (currentThumb) {
      const {x, width} = currentThumb.getBoundingClientRect();
      const thumbnailContainerWidth = thumbnailsRef.current?.offsetWidth || 0;
      const thumbnailContainerX =
        thumbnailsRef.current?.getBoundingClientRect().x || 0;
      const offset = thumbnailContainerWidth / 2;
      if (x + thumbnailContainerX < 0) {
        thumbnailsRef.current?.scrollTo({
          left: x + thumbnailContainerX - offset,
          behavior: 'smooth',
        });
      } else if (x + width + thumbnailContainerX > thumbnailContainerWidth) {
        thumbnailsRef.current?.scrollTo({
          left:
            x + width + thumbnailContainerX - thumbnailContainerWidth + offset,
          behavior: 'smooth',
        });
      }
    }
  };
  useEffect(() => {
    handleSelectionChange();
  }, [selectedImage]);

  // const [center, setCenter] = useState<google.maps.LatLngLiteral>({
  //   lat: coordinate[1],
  //   lng: coordinate[0],
  // });
  // const [currLatLng, setCurrLatLng] = useState<any>({});
  // const [currLatLngError, setCurrLatLngError] = useState<any>({});
  // console.log(propLatLng);

  // const originRef = useRef<any>(`${coordinate[0]},${coordinate[1]}`);
  const navigate = useNavigate();
  const destinationRef = useRef<HTMLInputElement | null>(null);

  // originRef.current!.value === `${coordinate[0]},${coordinate[1]}`;

  useEffect(() => {
    let abortController = new AbortController();
    let fetchID;
    if (location?.state?.id) {
      fetchID = location.state?.id;
    } else {
      const pathname = location.pathname;
      fetchID = pathname.match(/\/property\/([^/]+)/)[1];
    }
    if (propertyInfo?.neighbour_story) {
      setNStory(Object.entries(propertyInfo?.neighbour_story));
    }

    getSingleProperty(fetchID, abortController.signal)
      .then((res: any) => {
        setFecthedSingleProp(res.data);

        setPropertyInfo(res.data);
        setSelectedImage(res.data?.coverImage || res.data?.otherImages[0]);
        setNStory(Object.entries(res.data?.neighbour_story));

        // setCoordinate(res.data.location?.coordinates);
        setCenter({
          lat: res.data.location?.coordinates[1],

          lng: res.data.location?.coordinates[0],
        });
        setPropLatLng(`${res.data.location?.coordinates[1]},
          ${res.data.location?.coordinates[0]}`);
      })
      .catch((err) => {
        console.log(err.errors[0].message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.id, location.pathname]);

  // console.log(tryNStory);

  // const tryNStory = Object.entries(propertyInfo?.neighbour_story);
  // const try2 = tryNStory.map((item: any) => {
  //   const innerMap = item[1].map((re: any) => re);
  //   return innerMap;
  // });

  // const final = try2.flat();
  // console.log(final);
  // useEffect(() => {
  //   const lat = coordinate[1];
  //   const lng = coordinate[0];
  //   setPropLatLng(`${lat},${lng}`);
  //   setCenter({
  //     lat: coordinate[1],
  //     lng: coordinate[0],
  //   });
  // }, [propLatLng, location.pathname]);

  // useEffect(() => {
  //    navigator.geolocation.getCurrentPosition(
  //     ({ coords: { latitude, longitude } }) => {
  //       if (!latitude || !longitude) {
  //         console.log(currLatLngError);

  //         setCurrLatLngError('Kindly Enable location service on your device');
  //         return;
  //       }
  //       setCurrLatLng(`${latitude},${longitude}`);
  //     }
  //   );

  // }, []);

  async function calculateRoute() {
    if (
      // originRef.current!.value === '' ||
      destinationRef.current!.value === ''
    ) {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      // origin: originRef.current!.value,
      origin: propLatLng,
      destination: destinationRef.current!.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    // console.log(results);

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance!.text);
    setDuration(results.routes[0].legs[0].duration!.text);
  }
  async function calculateStory() {
    // if (
    //   // originRef.current!.value === '' ||
    //   destinationRef.current!.value === ''
    // ) {
    //   return;
    // }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      // origin: originRef.current!.value,
      origin: propLatLng,
      destination: des,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    // console.log(results);

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance!.text);
    setDuration(results.routes[0].legs[0].duration!.text);
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance('');
    setDuration('');
    // originRef.current!.value = '';
    destinationRef.current!.value = '';
  }

  if (!propertyInfo || !fecthedSingleProp) {
    return <h4>Loading...</h4>;
  }

  return (
    <div className='container-lg container-fluid-md mt-5 '>
      <span
        className=' mt-2 mb-3 d-block'
        onClick={() => {
          navigate(-1);
        }}
      >
        <i className='fa fa-chevron-left text-primary'></i>
      </span>
      <GetMeta
        description={propertyInfo?.description}
        title={`${propertyInfo?.title} (Ref:${propertyInfo?.referenceNumber}) | Krent`}
        canonicalValue={location?.pathname}
      />
      <div className='col'>
        <h1 className='fw-bolder mb-1 fs-32'>{propertyInfo?.title}</h1>
        <p className='text-secondary fs-14 mb-2'>
          {propertyInfo?.location!?.city || propertyInfo?.location2?.city}
        </p>
        {propertyInfo?.referenceNumber && (
          <p className='mb-2'>Ref : {propertyInfo.referenceNumber}</p>
        )}
      </div>

      <div className='row mb-4'>
        <div className='col-12 col-md-8'>
          <div className='position-relative'>
            <img
              src={selectedImage}
              alt={propertyInfo?.slug}
              className='rounded img-fluid mb-3'
              title={propertyInfo?.title}
            />
            <div className='buttons d-flex justify-content-center'>
              <span
                className='btn btn-cp mr-5 prev-button'
                onClick={handlePrevClick}
              >
                <i className='fa fa-chevron-left'></i>
              </span>
              <span
                className='btn btn-cp ml-5 next-button'
                onClick={handleNextClick}
              >
                <i className='fa fa-chevron-right'></i>
              </span>
            </div>
          </div>
          <div
            className='col-12 justify-content-center no-wrap d-flex flex-col mb-3  mb-md-0 '
            style={{overflowX: 'scroll'}}
            ref={thumbnailsRef}
          >
            {propertyInfo?.otherImages?.map((image: string, i: number) => {
              if (image !== 'undefined') {
                return (
                  <div
                    style={{
                      height: '50px',
                      width: '75px',
                      minWidth: '50px',
                      maxHeight: '75px',
                      objectFit: 'cover',
                      marginRight: '0.5rem',
                    }}
                    key={i}
                    onClick={() => handleThumbClick(image)}
                    className='cursor-pointer'
                  >
                    <img
                      src={image}
                      title={propertyInfo?.title}
                      alt={propertyInfo?.slug}
                      className={`rounded h-100 w-100  ${
                        image === selectedImage ? 'currentImage' : ''
                      } `}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div className='row'>
            <div
              className='col-6 col-md-12 mb-md-3 mb-0 cursor-pointer '
              onClick={() => setSelectedImage(propertyInfo.coverImage!)}
            >
              <img
                src={
                  selectedImage !== propertyInfo.coverImage
                    ? propertyInfo?.coverImage || propertyInfo?.otherImages[0]
                    : propertyInfo?.otherImages[1]
                }
                alt={propertyInfo?.slug}
                className='rounded img-fluid'
                title={propertyInfo?.title}
              />
            </div>
            <div className='col-6 col-md-12 position-relative d-flex justify-content-center'>
              {propertyInfo.video_url && (
                <div
                  className='position-absolute  d-grid  h-100 w-100'
                  style={{
                    placeItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                      background: '#fff',
                      display: 'grid',
                      placeItems: 'center',
                    }}
                  >
                    <PropertyVideo url={propertyInfo.video_url} />
                  </div>
                </div>
              )}
              <img
                src={propertyInfo?.otherImages[1]}
                alt={propertyInfo?.slug}
                title={propertyInfo?.slug}
                className='rounded img-fluid'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-8'>
          <div className='card mb-4'>
            <div className='card-body'>
              <div
                className={`row row-cols-3 row-cols-md-3 row-cols-lg-${
                  propertyInfo?.intent !== 'shortlet' ? '5' : '4'
                } g-4 `}
              >
                <div>
                  <p className='mb-1'>Bedrooms</p>
                  <span className='fs-14 fw-bold'>
                    <i className='fa-light fa-bed-front'></i>&nbsp;{' '}
                    {propertyInfo?.bedrooms}
                  </span>
                </div>

                <div>
                  <p className='mb-1'>Bathrooms</p>
                  <span className='fs-14 fw-bold'>
                    <i className='fa-light fa-bath'></i>&nbsp;{' '}
                    {propertyInfo?.bathrooms}
                  </span>
                </div>

                {/* {propertyInfo?.intent !== 'shortlet' && (
                  <div>
                    <p className='mb-1'>Square Area</p>
                    <span className='fs-14 fw-bold'>
                      <i className='fa-light fa-ruler-triangle'></i>&nbsp; 6 x
                      8m
                      <sup>2</sup>{' '}
                    </span>
                  </div>
                )} */}

                <div>
                  <p className='mb-1'>Status</p>
                  <span className='fs-14 fw-bold text-capitalize'>
                    <i className='fa-light fa-sign-hanging'></i>&nbsp; For{' '}
                    {propertyInfo?.intent}
                  </span>
                </div>
                {propertyInfo?.flood_meter && (
                  <div>
                    <p className='mb-1'>Flood Meter</p>
                    <span className='fs-14 fw-bold text-capitalize'>
                      <i className='fa fa-thermometer-full fa-sign-hanging'></i>
                      &nbsp;
                      {propertyInfo?.flood_meter}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <h4 className='fw-bolder'>About this home</h4>
          <p className='text-secondary fs-14'>{propertyInfo?.description}</p>

          <div className='d-flex bg-light flex-column mt-3 border rounded py-3 px-4 my-4'>
            <h5 className='text-secondary fs-14 mb-3'>
              Listed by property {propertyInfo?.affiliation || 'owner'}
            </h5>

            <div className='d-flex flex-row justify-content-start gap-3'>
              <div style={{width: '50px'}}>
                <img
                  src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYmjgLRoT8n7K6e-AcgCKsZJ7061fCJuw2ww&usqp=CAU'
                  alt={propertyInfo?.user.email}
                  className='img-fluid rounded-circle '
                />
              </div>
              <div>
                <p className='fw-bolder mb-0'>
                  {propertyInfo?.user?.preferredName ||
                    `${propertyInfo?.user?.firstname} ${propertyInfo?.user?.lastname}`}
                </p>
                <p className='text-secondary fs-14'>
                  {propertyInfo?.user.role}
                </p>
              </div>
            </div>
          </div>
          <div>
            {propertyInfo?.amenities.length > 0 && (
              <>
                <h4 className='fs-5 fw-bold my-3'>What this place offers</h4>
                <div className='row'>
                  {propertyInfo?.amenities
                    ?.map((item: any, i: number) => {
                      return (
                        <div className='col-6 col-md-4 col-lg-3 mb-3 ' key={i}>
                          <div className='row'>
                            <div className='col fw-bold  '>{item.title}</div>
                            {item.offers.map((a: string, index: number) => {
                              return (
                                <div
                                  className=' light-text fs-14'
                                  key={index + a}
                                >
                                  {a}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                    .slice(
                      0,
                      window.innerWidth <= 400
                        ? 2
                        : window.innerWidth > 400 && window.innerWidth <= 768
                        ? 3
                        : 4
                    )}
                  {propertyInfo.amenities.length > 2 && (
                    <span
                      className='text-link fs-13 d-flex align-items-center'
                      onClick={() => setOpenAmenities(true)}
                    >
                      Show all {propertyInfo.amenities.length} Amenities
                      <i className=' iconly iconly-Arrow-Right-2'></i>
                    </span>
                  )}
                </div>
              </>
            )}
            {/* <Calendar /> */}
          </div>
          <hr className='hr mt-4' />
          <div className='d-flex flex-column mb-5'>
            {isLoaded && (
              <>
                <div className='flex'>
                  <h3 className='fs-5 fw-bold my-4'>Map</h3>

                  <form className='mt-4 mt-md-3'>
                    <div className='row'>
                      <div className='form-group col-12 col-md-6 mb-3'>
                        <label
                          className='text-uppercase fw-600 fs-12 m-1'
                          htmlFor='property'
                        >
                          Find the distance from
                        </label>
                        <div className='input-group d-flex align-items-center'>
                          <span
                            className='input-group-text input-group-text-0 border-0'
                            id='basic-addon1 '
                          >
                            <i className={`iconly-Location icli fs-4`}></i>
                          </span>
                          <span className='m-0 text-uppercase fw-600 text-primary'>
                            This Property
                          </span>
                        </div>
                        <div className='input-group d-none'>
                          <span
                            className='input-group-text input-group-text-0 '
                            id='basic-addon1'
                          >
                            <i className={`iconly-Location icli fs-4`}></i>
                          </span>
                          <Autocomplete className='form-control   border-start-0'>
                            <input
                              type='text'
                              className='form-control form-control-0 ps-3 border-0 py-2 border-start-0 '
                              id='property'
                              placeholder='This Property'
                              onChange={(e) => setPropLatLng(e.target.value)}
                              value={propLatLng}
                              // ref={originRef}
                              style={{borderRight: 'none'}}
                            />
                          </Autocomplete>
                        </div>
                      </div>

                      <div className='form-group col-12 col-md-6 mb-3'>
                        <label
                          className='text-uppercase fw-600 fs-12 m-1'
                          htmlFor='distance'
                        >
                          to
                        </label>
                        <div className='input-group'>
                          <span
                            className='input-group-text input-group-text-0 '
                            id='basic-addon1'
                          >
                            <i className={`iconly-Location icli fs-4`}></i>
                          </span>
                          <Autocomplete className='form-control   border-start-0'>
                            <input
                              type='text'
                              className='form-control form-control-0 ps-3 border-0 py-2 border-start-0'
                              id='distance'
                              // aria-describedby='emailHelp'
                              placeholder='Enter address'
                              ref={destinationRef}
                              required
                              style={{borderRight: 'none'}}
                            />
                          </Autocomplete>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className='row d-flex align-items-center justify-content-between  '>
                    <div className='row col-12 col-md-6'>
                      <p className='fw-bold col-12 col-md-6'>
                        Distance:{' '}
                        <span className='text-danger'>{distance}</span>
                      </p>
                      <p className='fw-bold col-12 col-md-6'>
                        Duration:{' '}
                        <span className='text-danger'>{duration}</span>
                      </p>
                    </div>
                    <div className='row col-12 col-md-6 mb-3 d-flex justify-content-evenly  justify-content-md-evenly'>
                      <button
                        className='btn btn-primary map-btn fs-12 fw-600  px-4 px-md-0 col-5  col-md-4'
                        onClick={() => {
                          calculateRoute();
                        }}
                      >
                        Get route
                      </button>

                      <button
                        className='btn btn-outline-primary map-btn  fs-12 fw-600 py-2 px-4 px-md-0  col-5 col-md-4'
                        onClick={clearRoute}
                      >
                        Clear route
                      </button>
                    </div>
                  </div>
                </div>

                {center && (
                  <div style={{height: '25rem', position: 'relative'}}>
                    <GoogleMap
                      center={center}
                      zoom={15}
                      mapContainerStyle={{width: '100%', height: '100%'}}
                      onLoad={(map) => {
                        setMap(map);
                      }}
                    >
                      <Marker position={center} />

                      {directionsResponse && (
                        <DirectionsRenderer directions={directionsResponse} />
                      )}
                    </GoogleMap>

                    <div
                      className='me-auto bg-white rounded-circle d-flex align-items-center justify-content-center social-icon mb-2 cursor-pointer'
                      style={{
                        position: 'absolute',
                        left: '30px',
                        bottom: '30px',
                      }}
                    >
                      <i
                        className='fa fa-location-arrow fs-22 text-primary'
                        onClick={() => {
                          map!.panTo(center);
                          map!.setZoom(15);
                        }}
                      ></i>
                    </div>
                  </div>
                )}
              </>
            )}

            <hr className='mt-4 hr' />
            {/* {propertyInfo.rules && ( */}
            {/* )} */}
          </div>
          {propertyInfo.intent === 'shortlet' && (
            <div className='row col-12 '>
              <div className='col-md-6 '>
                <h4 className='fs-14 fw-bold '>House Rules</h4>
                {/* <p className='light-text fs-13'>Check-in after 2:00 PM </p>
              <p className='light-text fs-13'>Checkout before 11:00 AM</p> */}
                {propertyInfo?.rules ? (
                  <p className='light-text fs-13'>
                    {propertyInfo.rules.length > 30
                      ? `${propertyInfo.rules.substring(0, 30)} ...`
                      : propertyInfo.rules}{' '}
                  </p>
                ) : (
                  <p className='light-text fs-13'>No house rules</p>
                )}
                {propertyInfo?.rules && propertyInfo?.rules?.length > 30 && (
                  <span
                    className='text-link d-flex align-items-center fs-13'
                    onClick={() => setOpenRules(true)}
                  >
                    Show more <i className=' iconly iconly-Arrow-Right-2'></i>
                  </span>
                )}
              </div>
              <div className='col-md-6 mt-5 mt-md-0'>
                <h4 className='fs-14 fw-bold '>Cancellation Policy</h4>
                <p className='light-text fs-14'>
                  This reservation is non-refundable.
                </p>
                <p className='light-text fs-14'>
                  Review the Host’s full cancellation policy which applies even
                  if you cancel for illness or disruptions caused by COVID-19.
                </p>
                <p className='light-text fs-14'>
                  {propertyInfo?.rules && propertyInfo?.rules}
                </p>
                <span
                  className='text-link d-flex align-items-center fs-13'
                  onClick={() => setOpenPolicy(true)}
                >
                  Show more <i className=' iconly iconly-Arrow-Right-2'></i>
                </span>
              </div>
              <hr className='mt-4 hr' />
            </div>
          )}
          <Link
            to={'/explore/'}
            className='text-link fw-bold my-4 d-flex align-items-center'
          >
            See more listings <i className=' iconly iconly-Arrow-Right-2'></i>
          </Link>
        </div>
        <div className='col-12 col-md-4'>
          <PriceCard
            id={propertyInfo?.id}
            type={propertyInfo?.intent}
            price={propertyInfo?.price}
            duration={propertyInfo?.cycle}
            currency={propertyInfo?.currency}
            owner={propertyInfo?.user?.id}
            ownerName={propertyInfo?.user?.preferredName}
            virtualTour={
              propertyInfo?.virtual_tour_url && propertyInfo?.virtual_tour_url
            }
          />
          {nStory?.length > 0 && (
            <div className='border-1 rounded border-purple '>
              <div className='d-flex flex-column p-4 pb-0'>
                <h5 className='fw-bold mb-2'>Neighbourhood</h5>
                <p className='mb-0'>Landmarks around this property</p>
                <span className='mb-0 fs-12 text-primary'>
                  Double click on any card to see the distance and duration to
                  this property on the map.
                </span>
                <hr />
              </div>
              <div
                className='d-flex flex-column p-4 pt-0'
                style={{height: '40rem', overflow: 'scroll '}}
              >
                {nStory?.map((item: [string, StoryObject[]], index: number) => {
                  return (
                    <NeighbourStory
                      item={item}
                      key={index + 'a'}
                      setDes={setDes}
                      calculateStory={calculateStory}
                      destinationRef={destinationRef}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Terms and Condition Modal */}
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        dismissible={false}
      >
        <div className='container py-3'>
          <p className='fw-bold '>House Rules</p>
          <p>{propertyInfo?.rules && propertyInfo?.rules}</p>

          <button
            data-bs-dismiss='modal'
            className='btn btn-primary rounded-pill px-5 py-3 text-white fw-bold mt-3'
          >
            I understand
          </button>
        </div>
      </Modal>
      {/* Amenities Modal */}
      <NewModal
        open={openAmenities}
        center
        onClose={() => setOpenAmenities(false)}
        // classNames={{ modal: 'w-300 rounded' }}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
          modal: 'mt-7 mt-md-0',
        }}
        animationDuration={800}
      >
        <div className='py-5 px-2'>
          {propertyInfo?.amenities.length > 0 && (
            <>
              <h4 className='fs-5 fw-bold my-3'>All Amenities</h4>
              <hr />
              <div className='row'>
                {propertyInfo?.amenities?.map((item: any, i: number) => {
                  return (
                    <div className='col-6 col-md-4 col-lg-3 mb-3 ' key={i}>
                      <div className='row'>
                        <div className='col fw-bold  '>{item.title}</div>
                        {item.offers.map((a: string, index: number) => {
                          return (
                            <div className=' light-text fs-14' key={index + a}>
                              {a}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {/* <Calendar /> */}
        </div>
      </NewModal>
      {/* Policy Modal */}
      <NewModal
        open={openPolicy}
        center
        onClose={() => setOpenPolicy(false)}
        // classNames={{ modal: 'w-300 rounded' }}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        animationDuration={800}
      >
        <div className='py-5 px-2'>
          <>
            <div className='row'>
              <h4 className='fs-5 fw-bold mt-1'>Cancellation Policy</h4>
              <hr />
              <p>All Cancellation Policy will appear here</p>
            </div>
          </>
        </div>
      </NewModal>
      {/* House Rules Modal */}
      <NewModal
        open={openRules}
        center
        onClose={() => setOpenRules(false)}
        // classNames={{ modal: 'w-300 rounded' }}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        animationDuration={800}
      >
        <div className='pt-3 px-2'>
          <div className='row'>
            <h4 className='fs-5 fw-bold my-3'>House Rules</h4>
            <hr />
            <p className='light-text'>{propertyInfo?.rules}</p>
          </div>
        </div>
      </NewModal>
    </div>
  );
};
