import {DashboardLayout} from '@layouts/index';
import {TransactionCard} from '@shared/cards';
// import { compareAsc } from 'date-fns';
import {invoices} from './data';
import notification from '../../assets/images/notifications.png';
import pinImg from '../../assets/images/pin2.png';
import {Fragment, useEffect, useState} from 'react';
import {
  // LinkAccount,
  // getBankCodes,
  getWallet,
  getWalletTransactions,
  // saveAccount,
  sendOTP,
  validateOTP,
  withdrawFunds,
} from '@services/krent.service';
import {useSessionStorage} from '../../hooks';
import {Modal} from '@shared/controls/Modal';
import {Link} from 'react-router-dom';
import ktoast from '@services/toast.service';
import AddBankAccount from 'components/AddBankAccount';
// import {addBankAccountDetails} from './addBankAccountDetails';

interface WalletProps {
  balance: {
    available: number;
    book: number;
  };
  credits: number;
  currency: 'NGN' | 'USD';
  debits: number;
  disabled: boolean;
  id: string;
  paymentMethod: {
    accountName: string;
    accountNumber: string;
    accountType: 'Current' | 'Savings';
    bankName: string;
    sortCode: string;
  };
  user: {
    id: string;
    role: string;
  };
}

interface TransactionProps {
  id: string;
  amount: number;
  destination: string;
  initiatedAt: Date;
  status: string;
  type: string;
  wallet: string;
  otherDetails?: string;
  processedBy: {
    id: string;
    name: string;
  };
}

// type Bank = {
//   active: boolean;
//   code: string;
//   country: string;
//   createdAt: string;
//   currency: string;
//   gateway: string;
//   id: number;
//   is_deleted: boolean;
//   longcode: string;
//   name: string;
//   pay_with_bank: boolean;
//   slug: string;
//   type: string;
//   updatedAt: string;
// };
export type Bank = {
  value: string;
  label: string;
};

export const Wallet = () => {
  const [user] = useSessionStorage('user', {});
  const [loading, setLoading] = useState<boolean>(false);
  const [working, setWorking] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isWithdrawModal, setWithrawalModal] = useState<boolean>(false);
  const [modalSize, setModalSize] = useState<'lg' | 'md' | 'sm' | number>('lg');
  const [isEditAccountModalOpen, openEditAccountModal] =
    useState<boolean>(false);
  const [isOtpSent, setOtpSent] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<boolean>(false);
  const [isPinRequired, setPinRequired] = useState<boolean>(false);
  const [isVerifying, setIsVerifiying] = useState<boolean>(false);
  // const [isSaving, setIsSaving] = useState<boolean>(false);
  const [wallet, setWallet] = useState<WalletProps>();
  const [transactions, setTransactions] = useState<TransactionProps[]>([]);
  // const [accountNumber, setAccountNumber] = useState('');
  // const [bankCode, setBankCode] = useState('');
  // const [bankName, setBankName] = useState('');
  // const [accountName, setAccountName] = useState('');
  // const [accountType, setAccountType] = useState('');
  // const [pin, setPin] = useState('');
  // const [sortCode, setSortCode] = useState('');
  // const [banks, setBanks] = useState<Bank[]>([]);
  // const [filteredBanks, setFilteredBanks] = useState<Bank[]>([]);
  const [showAddBank, setShowAddBank] = useState(false);
  // const [bankLoading, setBankLoading] = useState(false);
  // const [accountSuccess, setAccountSuccess] = useState('');
  // const [accountError, setAccountError] = useState('');
  const [hasPin, setHasPin] = useState<boolean>(user?.hasPIN);

  const [withdrawalAmount, setWithdrawalAmount] = useState<string>();
  const [showWithdrawalPin, setShowWithdrawalPin] = useState<boolean>(false);
  const [withdrawalLoading, setWithdrawalLoading] = useState<boolean>(false);
  const [withdrawalError, setWithdrawalError] = useState<string>('');

  // fetch wallet function
  const fetchWallet = () => {
    setLoading(true);
    const {wallet: walletID} = user;
    let abortController = new AbortController();
    if (walletID) {
      getWallet(walletID, abortController.signal)
        .then((res: any) => {
          // console.log(res);
          setWallet(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setModalOpen(true);
    }
    return () => {
      abortController.abort();
      setModalOpen(false);
    };
  };

  const handleVerifyOTP = () => {
    const code = Array.prototype.map
      .call(document.getElementsByClassName('digits'), (e) => e.value)
      .join('');
    let abortController = new AbortController();
    setIsVerifiying(true);
    validateOTP({otp: code}, abortController.signal)
      .then(() => {
        setPinRequired(true);
      })
      .catch(() => {
        setOtpError(true);
        ktoast.error('Failed to validate OTP');
      })
      .finally(() => {
        setIsVerifiying(false);
      });
  };

  // const handleGetBanks = () => {
  //   setBankLoading(true);
  //   getBankCodes()
  //     .then((resp: any) => {
  //       const formattedBanks = resp.data.map((bank: any) => ({
  //         value: bank.code,
  //         label: bank.name,
  //       }));
  //       setBanks(formattedBanks);
  //       setFilteredBanks(formattedBanks);
  //       setBankLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // Verify Account
  // const handleVerifyAccount = () => {
  //   let abortController = new AbortController();
  //   setIsVerifiying(true);
  //   LinkAccount({accountNumber, bankCode}, abortController.signal)
  //     .then((resp: any) => {
  //       setAccountName(resp.data.account_name);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setAccountName('Not Found');
  //       ktoast.error('Failed to Verify Account');
  //     })
  //     .finally(() => {
  //       setIsVerifiying(false);
  //     });
  // };

  // Save Account
  // const handleSaveAccount = (e: any) => {
  //   e.preventDefault();
  //   let abortController = new AbortController();
  //   setIsSaving(true);
  //   let data;
  //   if (sortCode) {
  //     data = {
  //       accountName,
  //       accountNumber,
  //       bankName,
  //       accountType,
  //       pin,
  //       sortCode,
  //     };
  //   } else {
  //     data = {
  //       accountName,
  //       accountNumber,
  //       bankName,
  //       accountType,
  //       pin,
  //     };
  //   }
  //   saveAccount(
  //     {
  //       id: user.wallet,
  //       data,
  //     },
  //     abortController.signal
  //   )
  //     .then((resp: any) => {
  //       if (resp.success) {
  //         setAccountSuccess('Bank Account updated sucessfully');
  //       }
  //     })
  //     .catch((e) => {
  //       setAccountError(e.errors[0].message);
  //     })
  //     .finally(() => {
  //       setIsSaving(false);
  //       setAccountName('');
  //       setAccountNumber('');
  //       setAccountType('');
  //       setPin('');
  //       setSortCode('');
  //       setBankCode('');
  //     });
  // };
  // Withdraw funds
  // const handleWithdrawFunds = (e: any) => {
  //   e.preventDefault();
  //   let abortController = new AbortController();

  //   withdrawFunds(
  //     {
  //       id: user.wallet,
  //       data: {
  //         amount: withdrawalAmount,
  //         pin,
  //       },
  //     },
  //     abortController.signal
  //   )
  //     .then((resp: any) => {
  //       console.log(resp.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {});
  // };

  // const handleAccountNumberChange = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setAccountNumber(e.target.value);
  // };

  const handleCloseAccountModal = () => {
    setShowAddBank(false);
    // setAccountName('');
    // setAccountNumber('');
    // setAccountType('');
    // setPin('');
    // setSortCode('');
    fetchWallet();
    // setBankCode('');
    // setAccountSuccess('');
    // setAccountError('');
  };

  // const handleBankChange = (selectedBank: Bank | null) => {
  //   if (selectedBank) {
  //     setBankName(selectedBank.label);
  //     setBankCode(selectedBank.value);
  //   } else {
  //     setBankCode('');
  //   }
  // };

  // const handleBankSearch = (searchTerm: string) => {
  //   const filtered = banks.filter((bank) =>
  //     bank.label.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredBanks(filtered);
  // };

  // const handleAccountType = (e: any) => {
  //   setAccountType(e.target.value);
  // };

  // submit withdrawrequest
  const submitWithdrawalRequest = () => {
    setWithdrawalLoading(true);
    // e.preventDefault();
    let abortController = new AbortController();
    const code = Array.prototype.map
      .call(document.getElementsByClassName('digits'), (e) => e.value)
      .join('');
    withdrawFunds(
      {
        id: user.wallet,
        data: {
          amount: withdrawalAmount,
          pin: code,
        },
      },
      abortController.signal
    )
      .then((resp: any) => {
        if (resp.errors) {
          ktoast.error(resp.errors[0].message);
          setWithdrawalError(resp.errors[0].message);
          return;
        }
        if (resp.success) {
          // setShowWithdrawalPin(false);
          setWithdrawalAmount('');
          // setWithrawalModal(false);
          setWithdrawalError(resp.message);

          fetchWallet();

          ktoast.success(resp?.message);
        }

        // // Clear input values after successful submission
        // const digitsInputs = document.getElementsByClassName('digits');
        // for (let i = 0; i < digitsInputs.length; i++) {
        //   (digitsInputs[i] as HTMLInputElement).value = ''; // Reset each input value to empty
        // }
      })
      .catch((err) => {
        console.log(err);
        setShowWithdrawalPin(false);
        ktoast.error(err.errors[0].message);
        setWithdrawalError(err.errors[0].message);
      })
      .finally(() => {
        setWithdrawalLoading(false);

        // Clear input values after successful submission
        const digitsInputs = document.getElementsByClassName('digits');
        for (let i = 0; i < digitsInputs.length; i++) {
          (digitsInputs[i] as HTMLInputElement).value = ''; // Reset each input value to empty
        }
      });
    // setTimeout(() => {
    //   setWithdrawalLoading(false);
    // }, 2500);
  };
  const handleKeyDown = (e: any, currentInputId: string) => {
    if (e.key === 'Backspace' && e.target.value === '') {
      const previousInputId = getPreviousInputId(currentInputId);
      if (previousInputId) {
        document.getElementById(previousInputId)!.focus();
      }
    }
  };

  const getPreviousInputId = (currentInputId: string) => {
    // Define the order of  input fields
    const inputOrder = ['first', 'second', 'third', 'fourth'];
    const currentIndex = inputOrder.indexOf(currentInputId);

    // Check if the current input is not the first input
    if (currentIndex > 0) {
      return inputOrder[currentIndex - 1];
    }

    return null; // If there's no previous input
  };

  useEffect(() => {
    fetchWallet();
  }, [user]);

  // console.log(user, 'po');

  useEffect(() => {
    const {wallet: walletID} = user;

    let abortController = new AbortController();

    if (walletID) {
      getWalletTransactions(walletID, abortController.signal)
        .then((res: any) => {
          // console.log(res);
          setTransactions(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user]);

  useEffect(() => {
    const inputs = document.querySelectorAll<HTMLInputElement>('#otp > *[id]');

    const autoFocus = (
      event: React.KeyboardEvent<HTMLInputElement>,
      i: number
    ) => {
      if (event.key === 'Backspace') {
        inputs[i].value = '';
        if (i !== 0) inputs[i - 1].focus();
      } else {
        if (i === inputs.length - 1 && inputs[i].value !== '') {
          return true;
        } else if (event.keyCode > 47 && event.keyCode < 58) {
          inputs[i].value = event.key;
          if (i !== inputs.length - 1) inputs[i + 1].focus();
          event.preventDefault();
        } else if (event.keyCode > 64 && event.keyCode < 91) {
          inputs[i].value = String.fromCharCode(event.keyCode);
          if (i !== inputs.length - 1) inputs[i + 1].focus();
          event.preventDefault();
        }
      }
    };

    if (isOtpSent) {
      for (let i: number = 0; i < inputs.length; i++) {
        inputs[i].addEventListener('keydown', () => autoFocus);
      }
    }

    return () => {
      const inputs =
        document.querySelectorAll<HTMLInputElement>('#otp > *[id]');
      for (let i: number = 0; i < inputs.length; i++) {
        inputs[i].removeEventListener('keydown', () => autoFocus);
      }
    };
  }, [isOtpSent]);

  const generateWallet = () => {
    setWorking(true);
    let abortController = new AbortController();
    sendOTP(abortController.signal)
      .then(() => {
        // console.log(res);
        setOtpSent(true);
      })
      .then(() => {})
      .finally(() => {
        setWorking(false);
      });
  };

  return (
    <DashboardLayout>
      <div className='row mb-3'>
        <h3 className='fw-bold mb-1'>Wallet</h3>
        <p className='text-secondary fs-14'>
          Manage &amp; receive payments on your account
        </p>
      </div>

      <div className='row mb-5'>
        <div className='col-12 col-md-5 mb-3 mb-md-0'>
          <div className='card border-0 shadow-sm border-round h-100 pb-0'>
            <div className='p-4'>
              <h6 className='fs-14 mt-1'>Wallet Balance</h6>
              <h3 className='fw-bolder mt-2'>
                {wallet?.currency}{' '}
                {wallet?.balance?.available?.toLocaleString() || 'NGN 0.00'}
              </h3>
              <h3 className='fs-12 mt-2'>
                Book Balance - {} NGN{' '}
                {wallet?.balance?.book?.toLocaleString() || 'NGN 0.00'}
              </h3>
            </div>

            {wallet && wallet?.balance?.available > 0 && (
              <button
                className='btn btn-primary rounded-4 rounded-top-left-0 rounded-top-right-0'
                onClick={() => setWithrawalModal(true)}
              >
                Withdraw Funds
              </button>
            )}
          </div>
        </div>

        <div className='col-12 col-md-7'>
          <div className='card border-0 shadow-sm border-round p-4 h-100 d-flex flex-column justify-content-center'>
            <div className='row'>
              <div className='col'>
                <h6 className='fs-14 mt-1'>Connected Bank Account</h6>
              </div>
              <div className='col text-end'>
                {wallet?.paymentMethod &&
                  wallet?.paymentMethod?.bankName &&
                  wallet?.paymentMethod?.accountNumber && (
                    <button
                      disabled={loading}
                      className='btn btn-link p-0 text-decoration-none text-primary fw-bold fs-14'
                      type='button'
                      onClick={() => {
                        setShowAddBank(true);
                        // handleGetBanks();
                      }}
                    >
                      Edit Account
                    </button>
                  )}
              </div>
            </div>
            <h3 className='fw-bold mt-2'>
              {!wallet?.paymentMethod ||
              !wallet?.paymentMethod?.bankName ||
              !wallet?.paymentMethod?.accountNumber
                ? 'No account linked'
                : `${wallet?.paymentMethod?.accountNumber} / ${wallet?.paymentMethod?.bankName}`}
            </h3>
            {!wallet?.paymentMethod || !wallet?.paymentMethod?.accountName ? (
              <span
                className='text-link text-primary fs-14 fw-bold'
                onClick={() => {
                  setShowAddBank(true);
                  // handleGetBanks();
                }}
              >
                Link A Bank Account
              </span>
            ) : (
              <p className='fs-14 text-secondary'>
                {wallet?.paymentMethod?.accountName}
              </p>
            )}
            {/* <div className='container mt-5'>
              <h1 className='mb-4'>Account Verification</h1>
              <div className='mb-3'>
                <label htmlFor='accountNumber' className='form-label'>
                  Account Number
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='accountNumber'
                  value={accountNumber}
                  onChange={handleAccountNumberChange}
                />
              </div>

              <div className='mb-3'>
                <label htmlFor='bankSelect' className='form-label'>
                  Bank
                </label>
                <Select
                  id='bankSelect'
                  options={filteredBanks}
                  value={filteredBanks.find((bank) => bank.value === bankCode)}
                  onChange={handleBankChange}
                  onInputChange={(inputValue) => handleBankSearch(inputValue)}
                  isSearchable
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='accountName' className='form-label'>
                  Account Name
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='accountName'
                  value={accountName}
                  readOnly
                />
              </div>
              <button
                className='btn btn-primary'
                onClick={handleVerifyAccount}
                disabled={!accountNumber || !bankCode}
              >
                Verify Account
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div className='row mt-5 mt-md-4'>
        <div className='col-12 col-md-5 mb-5 mb-md-0'>
          <h6 className='fw-bold fs-16 mb-3'>Transactions</h6>
          {transactions?.length > 0 ? (
            <div
              className='card border-0 shadow-sm border-round p-3 overflow-auto scrollbar-custom'
              style={{height: 380}}
            >
              {transactions
                ?.sort(function compare(a, b) {
                  let dateA: any = new Date(a.initiatedAt);
                  let dateB: any = new Date(b.initiatedAt);

                  return dateB - dateA;
                })
                .map((transaction, key) => (
                  <TransactionCard
                    key={key}
                    description={`${transaction.status} to ${transaction.destination}`}
                    property={transaction?.otherDetails ?? ''}
                    time={transaction.initiatedAt}
                    type={transaction.type}
                    value={transaction.amount}
                  />
                ))}
            </div>
          ) : (
            <div className='card border-0 border-round p-5 text-center d-flex align-items-center'>
              <div>
                <h6 className='fs-18 mt-1 fw-bold mb-4'>No transactions yet</h6>
                <img src={notification} alt='No transactions' width={200} />
                <p className='fs-14 light-text'>
                  Any transactions you make would show up here. You don’t have
                  any right now
                </p>
              </div>
            </div>
          )}
        </div>
        <div className='col-12 col-md-7'>
          <h6 className='fw-bold fs-16 mb-3'>Unpaid Invoices</h6>
          {invoices?.length > 0 ? (
            <div className='card border-0 shadow-sm border-round p-3'>
              {invoices?.map((invoice, key) => (
                <TransactionCard
                  key={key}
                  description={invoice.description}
                  time={new Date()}
                  value={invoice.value}
                />
              ))}
            </div>
          ) : (
            <div className='card border-0 border-round p-5 text-center d-flex align-items-center'>
              <h6 className='fs-18 mt-1 fw-bold mb-4'>No invoices yet</h6>
              <img src={notification} alt='No transactions' width={200} />
              <p className='fs-14 light-text'>
                Any transactions you make would show up here. You don’t have any
                right now
              </p>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={isModalOpen}
        onClose={() => setModalOpen(false)}
        dismissible={false}
      >
        {!isOtpSent ? (
          <div className='text-center'>
            <p className='fs-14'>
              <span className='fw-bold'>Oops!</span> You do not have a wallet
              set up yet, Let's get you started.
            </p>
            <img
              src={notification}
              alt='No Wallet'
              className='img-fluid my-3'
              width='150'
              height='150'
            />
            <div className='d-grid gap-2 col-6 mx-auto'>
              <button
                className='btn btn-outline-dark rounded-pill px-5 py-3 fw-bolder fs-14'
                onClick={generateWallet}
              >
                {working ? (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                ) : (
                  'Get Started'
                )}
              </button>
              <Link to='/overview/' className='btn btn-link fs-12'>
                Go Back
              </Link>
            </div>
          </div>
        ) : (
          <div className='container height-100 d-flex justify-content-center align-items-center'>
            <div className='position-relative'>
              <div className='px-2 text-center'>
                <p className='fs-16'>
                  Please enter the one time password to verify your account
                </p>
                <p className='fs-14'>
                  A code has been sent to{' '}
                  <span className='fw-bold'>
                    *******{user?.phone.slice(-4)}
                  </span>
                </p>
                <div
                  id='otp'
                  className={`inputs d-flex flex-row justify-content-center mt-2${
                    otpError ? ' invalid-otp' : ''
                  }`}
                >
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded digits'
                    type='text'
                    id='first'
                    maxLength={1}
                    onFocus={() => setOtpError(false)}
                    onChange={(e) => {
                      if (e.target.value) {
                        document!.getElementById('second')!.focus();
                      }
                    }}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded digits'
                    type='text'
                    id='second'
                    maxLength={1}
                    onChange={(e) => {
                      if (e.target.value) {
                        document!.getElementById('third')!.focus();
                      }
                    }}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded digits'
                    type='text'
                    id='third'
                    maxLength={1}
                    onChange={(e) => {
                      if (e.target.value) {
                        document!.getElementById('fourth')!.focus();
                      }
                    }}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded digits'
                    type='text'
                    id='fourth'
                    maxLength={1}
                    onChange={(e) => {
                      if (e.target.value) {
                        document!.getElementById('fifth')!.focus();
                      }
                    }}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded digits'
                    type='text'
                    id='fifth'
                    maxLength={1}
                    onChange={(e) => {
                      if (e.target.value) {
                        document!.getElementById('sixth')!.focus();
                      }
                    }}
                  />
                  <input
                    className='m-2 py-4 px-3 text-center form-control rounded digits'
                    type='text'
                    id='sixth'
                    maxLength={1}
                  />
                </div>
                <div className='mt-4'>
                  <button
                    className='btn btn-primary rounded-pill px-5 py-2'
                    onClick={handleVerifyOTP}
                  >
                    Validate
                  </button>
                </div>
              </div>
              <div className='mt-3 text-center'>
                <div className='content d-flex justify-content-center align-items-center'>
                  <button
                    disabled={isVerifying}
                    className='btn btn-link fs-14 text-decoration-none'
                    onClick={generateWallet}
                  >
                    Didn't get the code?{' '}
                    <span className='text-dark'>Resend</span>
                  </button>
                </div>
                <button
                  onClick={() => setOtpSent(false)}
                  className='btn btn-link fs-12'
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        id='openEdit'
        show={isEditAccountModalOpen}
        onClose={() => {
          openEditAccountModal(false);
          setModalSize('lg');
          setPinRequired(false);
        }}
        size={modalSize}
      >
        {isPinRequired ? (
          <div
            className='container height-100 d-flex justify-content-center align-items-center'
            style={{width: 350}}
          >
            <div className='position-relative'>
              <div className='px-2 text-center'>
                <p className='fs-16 fw-600'>
                  Please enter your 4-digit pin to authorize
                </p>
                <div
                  id='otp'
                  className='inputs d-flex flex-row justify-content-center mt-2'
                >
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded'
                    type='text'
                    id='first'
                    maxLength={1}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded'
                    type='text'
                    id='second'
                    maxLength={1}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded'
                    type='text'
                    id='third'
                    maxLength={1}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded'
                    type='text'
                    id='fourth'
                    maxLength={1}
                  />
                </div>
                <div className='my-4'>
                  <button className='btn btn-primary rounded-pill px-5 py-2'>
                    Authorize
                  </button>
                </div>
              </div>
              {/* <div className="mt-3 text-center">
								<button onClick={() => openEditAccountModal(false)} className="btn btn-link fs-12">Cancel</button>
							</div> */}
            </div>
          </div>
        ) : (
          <div className='container'>
            <form className='px-4 pb-5'>
              <p className='fw-bold fs-20 mb-3'>Payments</p>

              <div className='row'>
                <div className='mb-4 col-md-6 col-12'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='form-label fs-14 fw-600'
                  >
                    Bank name
                  </label>
                  <select
                    className='form-select rounded-pill py-2 px-4'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                  >
                    <option>Guarantee trust bank (GTB)</option>
                  </select>
                </div>

                <div className='mb-4 col-md-6 col-12'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='form-label fs-14 fw-600'
                  >
                    Account Number
                  </label>
                  <input
                    className='form-control rounded-pill py-2 px-4'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                  />
                </div>
              </div>

              <div className='row'>
                <div className='mb-5 col-md-6 col-12'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='form-label fs-14 fw-600'
                  >
                    Account Name
                  </label>
                </div>
              </div>
              <button
                type='button'
                onClick={() => {
                  setModalSize('md');
                  setPinRequired(true);
                }}
                className='btn btn-primary rounded-pill py-3 px-5 fw-bold fs-14'
              >
                Save Bank Account
              </button>
            </form>
          </div>
        )}
      </Modal>

      {/* Add bank account modal */}
      <Modal id='addBank' show={showAddBank} onClose={handleCloseAccountModal}>
        <AddBankAccount id={user?.wallet} type='personal' />
      </Modal>
      <Modal
        id='openWithdraw'
        show={isWithdrawModal}
        onClose={() => {
          setWithrawalModal(false);
          setWithdrawalError('');
          setShowWithdrawalPin(false);
        }}
      >
        <div className='container'>
          {!showWithdrawalPin && (
            <Fragment>
              <p className='fs-22 fw-bold text-center'>Withdraw</p>
              <p className='text-end text-center fs-14'>
                Your Balance: {wallet?.currency}{' '}
                {wallet?.balance?.available?.toLocaleString() || 'NGN 0.00'}
              </p>

              <div className='input-group mb-3'>
                <input
                  className='form-control fs-1 mb-2 border-0 bg-light'
                  type='number'
                  placeholder='0.00'
                  value={withdrawalAmount}
                  onChange={(e) => setWithdrawalAmount(e.target.value)}
                />
                <span
                  className='input-group-text border-0 bg-transparent'
                  id='basic-addon2'
                >
                  {wallet?.currency}
                </span>
              </div>
              <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <button
                  type='button'
                  className='fs-12 btn btn-link mb-3 p-0 text-decoration-none'
                  onClick={() =>
                    setWithdrawalAmount(wallet?.balance?.available?.toString())
                  }
                >
                  Withdraw max
                </button>
              </div>
              <div className='d-flex justify-content-between mb-4'>
                <div>
                  <p className='fw-bold fs-14'>Withdrawing to: </p>
                </div>
                <div>
                  {wallet?.paymentMethod ? (
                    <Fragment>
                      <p className='mb-1 fs-12'>
                        {wallet?.paymentMethod?.bankName}
                      </p>
                      <p className='mb-1 fs-12'>
                        {wallet?.paymentMethod?.accountName}
                      </p>
                      <p className='mb-1 fs-12'>
                        {wallet?.paymentMethod?.accountNumber}
                      </p>
                    </Fragment>
                  ) : (
                    <p>No Payment Method Selected</p>
                  )}
                </div>
              </div>
              <div className='d-grid gap-2'>
                <button
                  className='btn btn-primary'
                  type='button'
                  onClick={() => setShowWithdrawalPin(true)}
                >
                  Continue
                </button>
              </div>
            </Fragment>
          )}

          {showWithdrawalPin && (
            <div
              className='container height-100 d-flex justify-content-center align-items-center'
              style={{width: 350}}
            >
              <div className='position-relative'>
                <div className='px-2 text-center'>
                  <p className='fs-16 fw-600'>
                    Please enter your 4-digit pin to authorize
                  </p>
                  {withdrawalError && (
                    <p className='text-danger'>{withdrawalError}</p>
                  )}
                  <div
                    id='otp'
                    className={`inputs d-flex flex-row justify-content-center mt-2${
                      otpError ? ' invalid-otp' : ''
                    }`}
                  >
                    <input
                      className='m-2 py-3 px-3 text-center form-control rounded digits'
                      type='password'
                      id='first'
                      maxLength={1}
                      onFocus={() => setOtpError(false)}
                      onKeyDown={(e) => handleKeyDown(e, 'first')}
                      onChange={(e) => {
                        if (e.target.value) {
                          document!.getElementById('second')!.focus();
                        }
                      }}
                    />
                    <input
                      className='m-2 py-3 px-3 text-center form-control rounded digits'
                      type='password'
                      id='second'
                      maxLength={1}
                      onKeyDown={(e) => handleKeyDown(e, 'second')}
                      onChange={(e) => {
                        if (e.target.value) {
                          document!.getElementById('third')!.focus();
                        }
                      }}
                    />
                    <input
                      className='m-2 py-3 px-3 text-center form-control rounded digits'
                      type='password'
                      id='third'
                      maxLength={1}
                      onKeyDown={(e) => handleKeyDown(e, 'third')}
                      onChange={(e) => {
                        if (e.target.value) {
                          document!.getElementById('fourth')!.focus();
                        }
                      }}
                    />
                    <input
                      className='m-2 py-3 px-3 text-center form-control rounded digits'
                      type='password'
                      id='fourth'
                      maxLength={1}
                      onKeyDown={(e) => handleKeyDown(e, 'fourth')}
                      // onChange={(e) => {
                      //   if (e.target.value) {
                      //     document!.getElementById('fifth')!.focus();
                      //   }
                      // }}
                    />
                    {/* <input
                      className='m-2 py-3 px-3 text-center form-control rounded digits'
                      type='text'
                      id='fifth'
                      maxLength={1}
                      onChange={(e) => {
                        if (e.target.value) {
                          document!.getElementById('sixth')!.focus();
                        }
                      }}
                    />
                    <input
                      className='m-2 py-4 px-3 text-center form-control rounded digits'
                      type='text'
                      id='sixth'
                      maxLength={1}
                    /> */}
                  </div>
                  <div className='my-4'>
                    <button
                      className='btn btn-primary rounded-pill px-5 py-2'
                      disabled={withdrawalLoading}
                      onClick={submitWithdrawalRequest}
                    >
                      {withdrawalLoading ? (
                        <span className='spinner-border spinner-border-sm'></span>
                      ) : (
                        'Authorize'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        id='hasPin'
        show={!hasPin}
        onClose={() => setHasPin(false)}
        dismissible={false}
      >
        <div className='text-center'>
          <p className='fs-14'>
            <span className='fw-bold'>Oops!</span> You are yet to set your
            transaction pin.
          </p>
          <img
            src={pinImg}
            alt='No Wallet'
            className='img-fluid my-3'
            width='150'
            height='150'
          />
          <div className='d-grid gap-2 col-6 mx-auto'>
            <Link
              to='/settings'
              className='btn btn-outline-dark rounded-pill px-5 py-3 fw-bolder fs-14'
            >
              Set Now
            </Link>
            <Link to='/overview/' className='btn btn-link fs-12'>
              Go Back
            </Link>
          </div>
        </div>
      </Modal>
    </DashboardLayout>
  );
};
